<template>
  <div class="wrap-edit">
    <el-card>
      <FloatTitBtn>
        <div class="edit-header">
          <span>建设工程规划许可证</span>
          <div>
            <el-button type="primary" v-if="!readOnly" @click="onSubmit"
              >保存</el-button
            ><el-button type="primary" @click="print">打印</el-button>
          </div>
        </div>
      </FloatTitBtn>
      <div class="edit-container">
        <el-form
          :disabled="readOnly"
          ref="baseform"
          label-position="left"
          :model="form"
          label-width="120px"
        >
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="项目代码:">
                <el-input v-model="form.XMDM" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="文号:">
                <el-input v-model="form.WH"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="建设单位:">
                <el-input v-model="form.JSDW" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="建设项目名称:">
                <el-input v-model="form.JSXMMC" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="建设位置:">
                <el-input v-model="form.JSWZ" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="建设规模:">
                <el-input
                  v-model="form.JSGM"
                  type="textarea"
                  :rows="5"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="附图附件名称:">
                <el-input
                  v-model="form.FTMC"
                  type="textarea"
                  :rows="5"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发证日期:">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.FZRQ"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  :clearable="false"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <div v-show="false">
      <div id="form2">
       <div style="font-family: 'SimSun';font-size: 17px;color: black;height:100%;position:relative;">
         <div style="width:49%;display:inline-block;height:100%;">
           <div style="margin-top: 210px;margin-left:75%;font-size:16px;white-space: nowrap;">{{ form.WH }}</div>
           <div style="margin-top: 410px;margin-left:74%;">慈溪市自然资源和规划局</div>
           <div style="margin-top: 40px;margin-left:75%;">{{ getDate(form.FZRQ) }}&ensp;</div>
         </div>

         <div style="display:inline-block;width:300px;position:absolute;top:8%;left:76.5%;">
           <div style="height: 50px;">
             {{ form.JSDW }}&ensp;
           </div>
           <div style="height: 50px;">
             {{ form.JSXMMC }}&ensp;
           </div>
           <div style="height: 50px;">
             {{ form.JSWZ }}&ensp;
           </div>
           <div style="height: 50px;">
             {{ form.JSGM }}&ensp;
           </div>
         </div>
         <div style="position:absolute;top:40%;left:67%;">
             <div v-for="(v, i) in picNameArray" :key="i" style="font-size: 17px;width:400px;">
                <span style="line-height:10px;">{{ v }}</span>
              </div>
           </div>  
       </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import print from "print-js";
import mgr from "@/services/security.js";
import FloatTitBtn from "../../components/FloatTitBtn.vue";
import dayjs from 'dayjs';
export default {
  name:'jsgcghxkz',
  components: { FloatTitBtn },
  data() {
    return {
      readOnly: true,
      form: {
        Id: "",
        BJID: "",
        WH: "",
        XMDM: "",
        JSDW: "",
        JSXMMC: "",
        JSWZ: "",
        JSGM: "",
        FTMC: "",
        FZRQ: new Date(),
        JBR: "",
      },
      picNameArray:['','']
    };
  },
  methods: {
    getDate(date) {
      var a = date
      a= dayjs(date).format('YYYY年MM月DD日')
      // console.log('打印时间：',a,a[0]);
      return a
    },
    print() {
      print({
        printable: "form2",
        type: "html",
        scanStyles: false,
        css: "/static/landscapeA3.css",
        documentTitle: "",
      });
    },
    async fetch(Id) {
      try {
        const response = await this.$ajax.get(
          this.$appConfig.apiUrls.ywxtApi + "/api/YW/Getjsgcghxkz?BJID=" + Id
        );

        if (response.data.IsSuccess) {
          this.form = response.data.Data;
          // this.form.FZRQ=new Date()
          this.form.XMDM = this.$store.state.XMDM;
          if(this.form.FTMC==null||this.form.FTMC==undefined){
            this.form.FTMC=''
          }
          this.picNameArray=this.form.FTMC.split('\n')
        } else {
          throw response.data.ErrorMessage;
        }

        const user = await mgr.getUser();
        if (this.form.JBR === user.profile.sub && this.$route.query.taskId) {
          this.readOnly = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onSubmit() {
      console.log('打印提交：',this.form);
      try {
        const response = await this.$ajax.post(
          this.$appConfig.apiUrls.ywxtApi + "/api/YW/AddOrUpdatejsgcghxkz",
          this.form
        );
        if (response.data.IsSuccess) {
          ElMessage({
            type: "success",
            message: "保存成功!",
          });
          this.form = response.data.Data;
          if(this.form.FTMC==null||this.form.FTMC==undefined){
            this.form.FTMC=''
          }
          this.picNameArray=this.form.FTMC.split('\n')
        } else {
          ElMessage({
            type: "info",
            message: "保存失败!",
          });
          throw response.data.ErrorMessage;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.form.BJID = this.$route.query.instanceId ?? "";
    this.fetch(this.form.BJID);
  },
};
</script>

<style scoped>
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.edit-container {
}
.el-form {
  width: 100%;
  overflow: hidden;
}
.input-width {
  width: 100%;
}
</style>
